<template>
  <div class="space-y-6">
    <div class="space-y-6 md:space-y-0 md:flex md:space-x-6">
      <base-input
        label="CEP"
        name="zipCode"
        type="text"
        v-model="zipCode"
        autocomplete="off"
        :autofocus="true"
        class="md:w-2/12"
        mask="#####-###"
        :has-error="!!zipCodeErrorMessage"
        :error-message="zipCodeErrorMessage"
      />
  
      <base-input
        label="Endereço"
        name="street"
        type="text"
        v-model="street"
        class="md:w-10/12"
        :has-error="!!streetErrorMessage"
        :error-message="streetErrorMessage"
      />
    </div>

    <div class="space-y-6 md:space-y-0 md:flex md:space-x-6">
      <base-input
        label="Número"
        name="number"
        type="text"
        v-model="number"
        class="md:w-2/12"
      />
  
      <base-input
        label="Complemento"
        name="complement"
        type="text"
        v-model="complement"
        class="md:w-5/12"
      />
  
      <base-input
        label="Bairro"
        name="neighborhood"
        type="text"
        v-model="neighborhood"
        class="md:w-5/12"
        :has-error="!!neighborhoodErrorMessage"
        :error-message="neighborhoodErrorMessage"
      />
    </div>

    <div class="space-y-6 md:space-y-0 md:flex md:space-x-6">
      <base-input
        label="Cidade"
        name="city"
        type="text"
        v-model="city"
        :has-error="!!cityErrorMessage"
        :error-message="cityErrorMessage"
      />

      <div class="flex flex-col w-full">
        <label
          for="state"
          :class="{'text-red-700': !!stateErrorMessage}"
        >
          Estado
        </label>
        <select
          id="state"
          v-model="state"
          class="mt-1 block w-full appearance-none focus:outline-none bg-transparent pr-8"
        >
          <option
            v-for="item in states"
            :key="item.uid"
            :value="item.uid"
            v-text="item.name"
          />
        </select>
        <span
          class="text-sm text-red-700 block"
          v-if="!!stateErrorMessage"
          v-html="stateErrorMessage">
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import BaseInput from '@/components/base/BaseInput.vue'
import { useCheckout } from '@/composables/checkout/useCheckout'
import { required, minLength, helpers } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { useI18n } from 'vue-i18n'
import states from './states'

export default defineComponent({
  components: {
    BaseInput,
  },

  setup () {
    const { t } = useI18n()

    const {
      checkoutFormData,
      updateCheckoutFormData
    } = useCheckout()

    const zipCode = computed({
      get: () => checkoutFormData.value.zipCode,
      set: (value) => updateCheckoutFormData({zipCode: value})
    })

    const street = computed({
      get: () => checkoutFormData.value.street,
      set: (value) => updateCheckoutFormData({street: value})
    })

    const number = computed({
      get: () => checkoutFormData.value.number,
      set: (value) => updateCheckoutFormData({number: value})
    })

    const complement = computed({
      get: () => checkoutFormData.value.complement,
      set: (value) => updateCheckoutFormData({complement: value})
    })

    const neighborhood = computed({
      get: () => checkoutFormData.value.neighborhood,
      set: (value) => updateCheckoutFormData({neighborhood: value})
    })

    const city = computed({
      get: () => checkoutFormData.value.city,
      set: (value) => updateCheckoutFormData({city: value})
    })

    const state = computed({
      get: () => checkoutFormData.value.state,
      set: (value) => updateCheckoutFormData({state: value})
    })

    const rules = computed(() => {
      return {
        zipCode: {
          required: helpers.withMessage(t('validations.required'), required),
          minLength: helpers.withMessage(t('validations.zipCode'),minLength(9)
          )
        },
        street: {
          required: helpers.withMessage(t('validations.required'), required)
        },
        number: {},
        complement: {},
        neighborhood: {
          required: helpers.withMessage(t('validations.required'), required)
        },
        city: {
          required: helpers.withMessage(t('validations.required'), required)
        },
        state: {
          required: helpers.withMessage(t('validations.required'), required)
        },
      }
    })

    // @ts-ignore
    const v$ = useVuelidate(rules, {zipCode, street, neighborhood, city, state })

    const zipCodeErrorMessage = computed(() => v$.value.zipCode.$errors[0]?.$message as string || undefined)
    const streetErrorMessage = computed(() => v$.value.street.$errors[0]?.$message as string || undefined)
    const neighborhoodErrorMessage = computed(() => v$.value.neighborhood.$errors[0]?.$message as string || undefined)
    const cityErrorMessage = computed(() => v$.value.city.$errors[0]?.$message as string || undefined)
    const stateErrorMessage = computed(() => v$.value.state.$errors[0]?.$message as string || undefined)

    return {
      zipCode,
      street,
      number,
      complement,
      neighborhood,
      city,
      state,
      zipCodeErrorMessage,
      streetErrorMessage,
      neighborhoodErrorMessage,
      cityErrorMessage,
      stateErrorMessage,
      states
    }
  }
})
</script>

<style scoped>
@layer components {
  select {
    @apply bg-white !important;
  }
}
</style>
